<template>
    <div>
        <web-banner :images="$store.getters.vendor.bannerImages" v-if="$store.getters.vendor.showBanner"></web-banner>
        <v-container>
            <div>
                <label v-if="coupons.length > 0" class="pl-6 title">Offers</label>
                <slider :couponArray="coupons"></slider>
            </div>
            <whatsapp-chat :moreBottomMargin="true"></whatsapp-chat>
            <product-card-list v-if="dealItems.length > 0" :category="dealInfo" :products="dealItems"
                title="Best Deals"></product-card-list>
            <div class="text-right my-4" v-if="dealItems.length > 0 || category.length > 0"
                :class="$vuetify.breakpoint.smAndUp ? 'mx-12' : 'mx-4'">
                <whatsapp-share :whatasppMsg="buildProductCatalogMsg()"></whatsapp-share>
            </div>
            <label class="mx-6 title">Categories</label>
            <v-row justify="center" class="mt-2">
                <v-col cols="4" md="3" v-for="item in category" :key="item._id">
                    <category-card :item="item"></category-card>
                </v-col>
            </v-row>
            <div v-for="(item, index) in category" :key="index">
                <product-card-list :products=[] :categoryId="item._id" :title="item.title" :category="item"
                    :showAll="true"></product-card-list>
            </div>
            <div v-if="category.length == 0" class="noContent text-center">
                <h1>No Products found</h1>
            </div>
            <div>
                <new-cart-bar :bottomMargin="true" v-if="$vuetify.breakpoint.xsOnly"></new-cart-bar>
            </div>
        </v-container>
    </div>
</template>

<script>
import ProductCardList from '@/webstoreComponents/ProductCardList'
import appConstants from '@/utils/appConstants'
import WebBanner from '@/webstoreComponents/WebBanner'
import CategoryCard from '@/webstoreComponents/CategoryCardWeb'
import NewCartBar from '@/webstoreComponents/NewCartBarMobile.vue';
import Slider from '@/components/Slider.vue'
import WhatsappChat from '@/components/WhatsappChat.vue'
export default {
    components: {
        ProductCardList,
        WebBanner,
        CategoryCard,
        NewCartBar,
        Slider,
        WhatsappChat
    },
    data() {
        return {
            dealItems: [],
            category: [],
            coupons: [],
            dealInfo: {
                categoryId: 0,
                title: appConstants.BEST_DEALS,
                image: {
                    path: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIA0qvks3G2TsjzfsjuK9w3WjxFWCuvaf6TA&usqp=CAU"
                }

            }
        }
    },
    created() {
        if (performance.navigation.type == 1)
            this.updateVendorSetting(this.$route.params.slug)
    },
    mounted() {
        this.$store.subscribeAction((action, state) => {
            this.refresh = action.type == 'actionUpdateVendor'
        })
        if (!this.refresh)
            this.initComponent();
    },
    methods: {
        async initComponent() {
            this.coupons = await this.getItem(appConstants.WEBSTORE_COUPONS)
            this.dealItems = await this.getItem(appConstants.WEBSTORE_PRODUCTS + '/bestDeals')
            this.category = await this.getItem(appConstants.WEBSTORE_CATEGORIES + "/parentCategories")
            if (this.$store.getters.vendor.licensingDetails.allowedModules.findIndex(rec => rec == 'package') > -1)
                this.category.push({ _id: '1', title: 'Bundle and Packages' })
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 24px;
}
</style>
