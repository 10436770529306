<template>
    <v-list-item two-line :elevation="showElevation ? 2 : 0" style="padding:1% 2%">
        <v-list-item-avatar tile size="65px">
            <v-img v-if="product.images[0]" :src="imagePath(product.images[0]?.path)" contain></v-img>
        </v-list-item-avatar>
        <v-list-item-content @click="showProductDetail(product._id)">
            <label class="body-1 product-title" v-text="product.title"></label>
            <label><span v-if="product.offerprice > 0" class="body-2 font-weight-light">
                    ₹{{ product.offerprice }}</span><span class="body-2 font-weight-light"
                    :class="product.offerprice > 0 ? 'strike-through ml-2' : ''">
                    ₹{{ product.price }}</span></label>
        </v-list-item-content>
        <v-list-item-action>
            <add-to-cart-btn v-model="product"></add-to-cart-btn>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import AddToCartBtn from '@/webstoreComponents/AddToCartBtn.vue';
export default {
    components: {
        AddToCartBtn,
    },
    props: ["showElevation", "product"],
};
</script>

<style scoped>
.product-title {
    white-space: break-spaces;
}

.strike-through {
    text-decoration: line-through;
    text-decoration-color: red;
}

.add-to-cart-button-align {
    margin-top: -1.5rem
}
</style>
