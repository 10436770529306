<template>
    <v-row>
        <v-col cols="12" md="8">
            <v-card flat>
                <v-card-text>
                    <v-row align="center">
                        <v-col>
                            <v-card-title :style="themeInverted">Cart Items</v-card-title>
                        </v-col>
                        <v-col v-if="order.items.length > 0" class="deleteBtn text-right">
                            <v-btn small @click="deleteAllProducts()" :style="theme">
                                <v-icon class="ma-1">mdi-delete</v-icon>
                                Remove All
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-simple-table v-if="this.$store.getters.cart.length > 0">
                        <tbody>
                            <tr v-for="item in order.items" :key="item._id">
                                <span v-if="$store.getters.vendor.storeUI == oldStoreUI">
                                    <cart-item :item="item" @updated="showQuantity" @delete="removeItem"></cart-item>
                                </span>
                                <span v-else-if="$store.getters.vendor.storeUI == newStoreUI">
                                    <basket-item :item="item" @updated="showQuantity"
                                        @delete="removeItem"></basket-item>
                                </span>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <div class="text-center" v-else>
                        <v-alert outlined color="grey lighten-1" icon="mdi-cart" prominent>
                            <h1>Your cart is empty</h1>
                        </v-alert>
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="4">
            <v-card flat>
                <v-card-text>
                    <v-card-title :style="themeInverted">Billing Details</v-card-title>
                    <table style="width:100%">
                        <tbody>
                            <tr>
                                <td>Subtotal</td>
                                <td colspan="2" class="text-right">{{ order.subTotal | amountFormat }}/-</td>
                            </tr>
                            <tr
                                v-if="$store.getters.vendor.enableBothRewardType || !$store.getters.vendor.enableBothRewardType && order.redeemedReward == 0">
                                <td class="text-right" colspan="3">
                                    <div class="text-right">
                                        <coupon v-model="order.discount.code" :order="order"
                                            @setAmount="setDiscountAmount" @clear="clearDiscount()"></coupon>
                                    </div>
                                </td>
                            </tr>
                            <tr
                                v-if="($store.getters.vendor.enableBothRewardType || !$store.getters.vendor.enableBothRewardType && order.discount.amount == 0) && $store.getters.vendor.enableRewards">
                                <td colspan="3">
                                    <show-reward @redeemReward="setReward" :orderTotal="order.subTotal"></show-reward>
                                </td>
                            </tr>
                            <tr v-if="order.discount.amount > 0">
                                <td>Discount</td>
                                <td colspan="2" class="text-right">-{{ order.discount.amount | amountFormat }}/-</td>
                            </tr>
                            <tr v-if="order.tax">
                                <td>Tax ({{ order.tax }}%)</td>
                                <td colspan="2" class="text-right">{{ order.taxAmount | amountFormat }}/-</td>
                            </tr>
                            <tr>
                                <td>Delivery Charges
                                    <label class="delivery-offer text-caption text-left">(Free Delivery above
                                        {{ $store.getters.vendor.freeDeliveryAbove | amountFormat }}/-)</label>
                                </td>
                                <td colspan="2" class="text-right">
                                    <strike
                                        v-if="total - order.delivery.charge >= $store.getters.vendor.freeDeliveryAbove">
                                        <v-icon small>mdi-currency-inr</v-icon>{{ order.delivery.charge | amountFormat
                                        }}/-
                                    </strike><br>
                                    <label
                                        v-if="total - order.delivery.charge < $store.getters.vendor.freeDeliveryAbove">
                                        <v-icon small>mdi-currency-inr</v-icon>{{ order.delivery.charge | amountFormat
                                        }}/-
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td class="title total">Total</td>
                                <td colspan="2" class="text-right total">
                                    <strike v-if="order.discount.amount > 0">
                                        <v-icon small>mdi-currency-inr</v-icon>{{ total + order.discount.amount |
                                            amountFormat }}/-
                                    </strike>
                                    <label v-else class="title">
                                        <v-icon>mdi-currency-inr</v-icon>{{ total | amountFormat }}/-
                                    </label>
                                </td>
                            </tr>
                            <tr v-if="this.$store.getters.cart.length > 0">
                                <td>
                                    <label>Expected Delivery</label>
                                </td>
                                <td colspan="2">
                                    <input-date label="Delivery Date" v-model="order.delivery.date"></input-date>
                                    <!-- <v-layout>
                                        <v-flex></v-flex>
                                        <v-flex text-right sm11 mt-2>
                                        </v-flex>
                                    </v-layout> -->
                                </td>
                            </tr>
                            <tr v-if="delivery.length == 0">
                                <td colspan="3" class="text-center">
                                    <label class="delivery-msg">{{ deliveryMsg }}</label>
                                </td>
                            </tr>
                            <tr v-if="delivery.length > 0 && order.items.length > 0 && delivery[0].code">
                                <td colspan="3">
                                    <delivery-type v-model="order.delivery.type" :type="delivery"></delivery-type>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"
                                    v-if="order.delivery.type.code == 'home-delivery' && order.items.length > 0">
                                    <delivery-address @update='saveAddress'
                                        :deliveryType="order.delivery.type.label"></delivery-address>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3"
                                    v-if="order.delivery.type.code == 'pick-up-from-shop' && order.items.length > 0">
                                    <pickup-point @update='savePickup'></pickup-point>
                                </td>
                            </tr>
                            <tr v-if="order.items.length > 0">
                                <td colspan="3">
                                    <payment-option v-model="order.paymentType"
                                        :type="$store.getters.vendor.paymentOption"></payment-option>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center">
                        <progress-bar :show="razorPay" size="35"></progress-bar>
                    </div>
                    <v-btn block v-if="$store.getters.vendor?.razorPay?.enabled" :style="theme" class="mb-2"
                        @click="payOnline()">Pay Now</v-btn>
                    <v-btn v-if="order.items.length > 0 && order.delivery.type" block :style="theme"
                        @click="checkout()">Place
                        Order</v-btn><br>
                    <v-btn block outlined :style="themeInverted"
                        :to="$store.getters.vendor.storeUI == oldStoreUI ? '/store' : '/store/categoryAndProducts/searchText'">Continue
                        Shopping</v-btn>
                </v-card-text>
                <package-subscription ref="subscription" @checkout="createSubscription($event)"
                    :showDialog="showPackageSubscription" :order="order" @close="showPackageSubscription = false" />
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import mixins from '@/mixins/commonFunctions.js'
import CartItem from '@/components/CartItem.vue'
import appConstants from '@/utils/appConstants'
import Coupon from '@/components/Coupon.vue'
import PickupPoint from '@/components/PickupPoint.vue'
import ShowReward from '@/components/ShowReward.vue'
import BasketItem from '@/webstoreComponents/BasketItem'
import PaymentOption from '@/components/PaymentOption'
import PackageSubscription from '@/components/PackageSubscription.vue'
export default {
    props: ['show'],
    mixins: [mixins],
    components: {
        CartItem,
        Coupon,
        PickupPoint,
        ShowReward,
        BasketItem,
        PaymentOption,
        PackageSubscription
    },
    computed: {
        drawer: {
            get() {
                return this.show == true ? true : false
            },
            set(newValue) { }
        },
        total() {
            return this.calculateTotal(this.order)
        },
        transaction() {
            return {
                txDate: new Date(),
                mode: 'PGATEWAY',
                txAmount: this.order.total,
                refNo: this.order.orderNo,
                narration: '',
                txType: 'CR',
                vendorId: this.order.vendorId
            }
        }
    },
    data() {
        return {
            order: {
                paymentType: '',
                items: [],
                pStatus: undefined,
                tax: 0,
                total: 200,
                taxAmount: 0,
                discount: {
                    amount: 0,
                    type: '',
                    percent: 0,
                    code: ''
                },
                subTotal: 150,
                delivery: {
                    date: ''
                },
                customer: {
                    _id: '',
                    displayName: "",
                    email: '',
                    address: '',
                    deleteAddress: []
                },
                channel: "WEBSTORE",
                delivery: {
                    type: '',
                    address: '',
                    charge: 0
                },
                redeemedReward: 0,
            },
            delivery: [],
            vendorId: '',
            deliveryMsg: '',
            razorPayload: {
                key: '',
                amount: 0,
                currency: "INR",
                name: "",
                description: "",
                notes: {},
                "handler": (response) => {
                    this.updateOrder(response)
                },
                "modal": {
                    "ondismiss": () => {
                        this.rejectOrder()
                    }
                },
                remember_customer: false,
                prefill: {},
            },
            razorPay: false,
            orderToUpdate: {},
            showPackageSubscription: false,
            productSubscription: []
        }
    },
    mounted() {
        this.razorPayload.key = this.$store.getters.vendor.razorPay.enable ? this.$store.getters.vendor.razorPay.key_id : ''
        this.initializeCart()
    },
    methods: {
        closeCart() {
            this.$emit('CLOSE_CART')
        },
        initializeCart() {
            this.order.items = []
            this.order.delivery.date = this.setDeliveryDate()
            this.deliveryMsg = this.$store.getters.vendor.deliveryMsg
            if (this.$store.getters.vendor.delivery.length > 0)
                this.delivery = this.$store.getters.vendor.delivery
            if (this.$store.getters.vendor.taxPercent)
                this.order.tax = this.$store.getters.vendor.taxPercent
            if (this.$store.getters.vendor.deliveryCharge > 0)
                this.order.delivery.charge = this.$store.getters.vendor.deliveryCharge
            var cartItems = this.$store.getters.cart
            if (cartItems) {
                this.order.items = cartItems.map(cartItem => this.convertProductToOrderItem(cartItem, cartItem.quantity))
            }
        },

        showQuantity(quantity, productName) {
            var index = this.order.items.findIndex(rec => rec.name == productName)
            this.order.items[index].quantity = quantity
        },
        checkout() {
            if (localStorage.getItem('userProfile')) {
                this.getUserDetails()
                this.order.vendorId = this.getUserProfile().vendorId
                this.order.pStatus = this.paymentStatus.COD
                if (this.checkDeliveryDate(this.order.delivery.date)) {
                    this.placeOrder(this.order)
                }
                //this.$router.push({name:"Checkout",params: {entity: this.order}})
            } else {
                this.razorPay = false
                alert("Signup/Log in to place order")
                this.$router.push("/store/login")
            }
        },
        async placeOrder(order) {
            if (order.delivery.type.code == 'home-delivery' && order.delivery.address == "")
                alert("Enter Address")
            else if (order.delivery.type.code == 'pick-up-from-shop' && !order.delivery.pickupPoint && this.$store.getters.vendor.pickupPoints.length > 0)
                alert("Please select a Pickup point")
            else {
                try {
                    let savedOrder
                    if (this.order.paymentType == 'online')
                        this.razorPay = true
                    if (this.razorPay)
                        await this.payWithRazor(order)
                    else {
                        let subscriptionPackage = order.items.filter(rec => rec.bType == this.packageModule.bType.FUTURE_BUY)
                        if (subscriptionPackage.length > 0) {
                            this.showPackageSubscription = true
                            this.$refs.subscription.initComponent()
                        }
                        else {
                            savedOrder = await this.postItem(appConstants.ORDERS_API, order)
                            this.orderAlert(savedOrder)
                        }
                    }
                } catch (error) {
                    this.handleError(error)
                    this.razorPay = false
                    console.log(error)
                    if (error.response.data.statusCode == 451)
                        alert('Vendor account is Inactive. Cannot place order')
                    else
                        alert(error.response.data)
                }
            }
            this.razorPay = false
        },
        orderAlert(savedOrder) {
            if (Object.keys(savedOrder.data).length != 0) {
                alert("Congratulations!! Your Order Placed successfully\nYour order number is " + savedOrder.data.orderNo)
                this.$store.dispatch("actionUpdateCart", [])
                this.$router.push("/store")
            }
        },
        removeItem(product) {
            //this.order.items=this.$store.getters.cart
            this.initializeCart()
        },
        saveAddress(address, saveAddress) {
            this.order.delivery.address = address
            if (saveAddress)
                this.order.customer.address = address
        },
        savePickup(address) {
            this.order.delivery.pickupPoint = address
        },
        getUserDetails() {
            this.order.customer._id = this.getUserProfile()._id
            this.order.customer.displayName = this.getUserProfile().displayName
            this.order.customer.value = this.getUserProfile().mobileNo
        },
        deleteAllProducts() {
            this.$store.dispatch("actionUpdateCart", [])
            this.initializeCart()
        },
        setDiscountAmount(amount) {
            this.order.discount.amount = amount
        },
        clearDiscount() {
            this.order.discount = {
                amount: 0,
                type: '',
                percent: 0,
                code: ''
            }
        },
        setReward(rewardPoint) {
            this.order.redeemedReward = rewardPoint
        },
        payOnline() {
            this.razorPay = true
            this.checkout()
        },
        async payWithRazor(order) {
            let savedOrder = await this.postItem(appConstants.ORDERS_API, order)
            this.orderToUpdate = savedOrder
            await this.updateRazorPayload(savedOrder)
            var razorCheckout = new Razorpay(this.razorPayload)
            razorCheckout.open()
            razorCheckout.on('payment.failed', async function (response) {
                alert(response.error.description);
            })
        },
        async updateRazorPayload(savedOrder) {
            this.razorPayload.name = this.$store.getters.vendor.businessName
            let user = this.getUserProfile()
            this.razorPayload.description = "OrderNo " + savedOrder.data.orderNo
            let amount = this.order.total * 100
            this.razorPayload.amount = parseInt(amount)
            this.razorPayload.notes['description'] = this.razorPayload.description
            this.razorPayload.prefill['name'] = this.getUserProfile().displayName
            this.razorPayload.prefill['contact'] = this.getUserProfile().mobileNo
            this.razorPayload.prefill['email'] = this.getUserProfile().email
            let orderDetails = await this.postItem(appConstants.BILLINGS_API, this.razorPayload)
            this.razorPayload['order_id'] = orderDetails.id
        },
        async updateOrder(response) {
            this.orderToUpdate.data['razorPaymentId'] = response.razorpay_payment_id
            this.orderToUpdate.data['pStatus'] = 'PAID'
            this.transaction.narration = 'Payment Completed'
            this.transaction.refNo = this.orderToUpdate.data.orderNo
            this.updateOrderWithTransaction(this.orderToUpdate.data)
            this.orderAlert(this.orderToUpdate)
        },
        async rejectOrder() {
            this.orderToUpdate.data['currentStatus'] = 'CANCELED'
            this.orderToUpdate.data['pStatus'] = 'FAILED'
            this.transaction.narration = 'Payment Canceled'
            this.updateOrderWithTransaction(this.orderToUpdate.data)
        },
        async updateOrderWithTransaction(order) {
            this.transaction.refNo = order.orderNo
            order.paymentDetails = this.transaction
            await this.putItem(appConstants.UPDATE_PAYMENT_DETAILS + "/" + order._id, order)
            await this.putItem(appConstants.ORDERS_API + "/" + order._id, order)
        }
    }
}
</script>

<style scoped>
.deleteBtn {
    padding: 1rem;
}

.delivery-msg {
    color: red;
    font-weight: 400;
    font-size: 16px;
}

.delivery-offer {
    color: grey;
    white-space: nowrap
}

.total {
    border-top: 1px solid gray !important;
    padding: 2px 0px;
}

@media only screen and (max-width: 500px) {
    .delivery-msg {
        font-size: 14px;
    }
}
</style>
